
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'HomepageBoardPassword',
})
export default class extends Vue {
  private password = '';

  private handleClickSubmit() {
    if (this.password) {
      this.$router.push({
        name: 'HomepagePostDetail',
        params: {
          nickname: this.$route.params.nickname,
          boardUid: this.$route.params.boardUid,
          postUid: this.$route.params.postUid,
        },
        query: {
          password: this.password,
        },
      });
    } else {
      alert('패스워드를 입력해주세요.');
    }
  }
}
